const en = {
  CLIENT_ONBOARDING: {
    CLIENT_ONBOARDING: "Client Onboarding",
    LEGAL_ENTITY_NAME: "Legal entity name",
    LOCATION: "Location",
    NAME_ON_FRONTIER: "Name on frontier",
    ACCOUNT_CREATED_BY: "Account created by",
    ACCOUNT_CREATED_ON: "Account created on",
    STATUS: "Status",
  },

  APPLICATION: {
    LEFT_MENU: {
      LOGOUT: "Logout",
    },
  },

  CLIENT_OFFBOARDING: {
    PAUSE_ACCOUNT: "Pause account",
    CLOSE_ACCOUNT: "Close account",
    CLOSE_CONFIRMATION_CONTENT:
      "Are you sure you wish to remove this business from the Frontier Finance platform and close the account?",
    DO_NOT_PROCEED_BUTTON: "Do not proceed",
    CONFIRM_CLOSE_BUTTON: "Confirm close account",
    INITIAL_APPROVAL: "Initial approval",
    PAUS_NOTIFIED: "PAUs notified",
    ACCCESS_REVOKED: "Access revoked",
    OFFBOARDING_COMPLETE: "Offboarding complete",
    BUSINESS: "Business",
    PRIMARY_AUTHORIZED_USER: "Primary authorized user",
    SUBSCRIPTION_PLAN: "Subscription plan",
    STARTS_ON: "Starts on",
    PROGRESS_STATUS: "Progress status",
    CLIENT_DETAILS: "Client details",
    CLOSE_ACCOUNT_REQUEST_HEADING: "Close account request received",
    CLOSE_ACCOUNT_REQUEST_BODY: "A request has been made to close this account as soon as possible.",
    DISCLAIMER: "Disclaimer",
    DISCLAIMER_BODY:
      "By approving, you indicate that you have reviewed all the details and agree to proceed with closing this account and removing the business from Frontier Finance.",
    DISCLAIMER_APPROVAL: "Please tick if you agree and are happy to proceed.",
    APPROVE: "Approve",
    REJECT: "Reject",
  },
};

export default en;
