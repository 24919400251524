import React, { useContext, useEffect, useState } from "react";
import HttpContext from "../../services/HttpContext";
import Table from "./Table";
import Constants from "../../Constants";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";

function AllClientsPage({ setNumberOfAwaitingRequests, search, debounced, setDebounced, tabNameKey }) {
  const { GetRequest } = useContext(HttpContext);
  const { t } = useTranslation();

  const [noRecordsFound, setNoRecordsFound] = useState(false);

  const fetchData = async (page, rowsPerPage) => {
    let url = `${Constants.REACT_APP_API_FETCH_ALL_CLIENTS}?pageNumber=${page}&pageSize=${rowsPerPage}`;

    if (search.trim() !== "") {
      url += `&search=${search}`;
    }

    const response = await GetRequest(url);

    const noRecords = response?.data?.length <= 0;
    setNoRecordsFound(noRecords);
    return response?.data ?? [];
  };

  useEffect(() => {
    setNoRecordsFound(false);
  }, [search]);

  const headerColumns = [
    { id: "badge", label: "", width: "5%" },
    { id: "legalEntityName", label: t("CLIENT_ONBOARDING:LEGAL_ENTITY_NAME") },
    { id: "countryCode", label: t("CLIENT_ONBOARDING:LOCATION") },
    { id: "businessName", label: t("CLIENT_ONBOARDING:NAME_ON_FRONTIER") },
    { id: "createdBy", label: t("CLIENT_ONBOARDING:ACCOUNT_CREATED_BY") },
    { id: "createdAt", label: t("CLIENT_ONBOARDING:ACCOUNT_CREATED_ON") },
    { id: "status", label: t("CLIENT_ONBOARDING:STATUS") },
    { id: "expandRow", label: "", width: "5%" },
  ];

  return (
    <Box style={{ textAlign: "center" }}>
      <Table
        tabNameKey={tabNameKey}
        headerColumns={headerColumns}
        fetchData={fetchData}
        setNumberOfAwaitingRequests={setNumberOfAwaitingRequests}
        search={search}
        noRecordsFound={noRecordsFound}
        debounced={debounced}
        setDebounced={setDebounced}
      />
    </Box>
  );
}

export default AllClientsPage;
