import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";

const StyledButton = styled(Button)(({ theme, variant }) => ({
  borderRadius: "1.25rem",
  textTransform: "none",
  ...(variant === "outlined" && {
    border: "1px solid #31313180",
    color: "#000000",
    marginRight: "1rem",
  }),
  ...(variant === "contained" && {
    background: "#E03400",
    border: "none",
    color: "#FFFFFF",
  }),
}));

function CloseAccountConfirmation({ open, onClose, onConfirm }) {
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: "1rem",
        },
      }}
    >
      <DialogTitle
        sx={{
          width: "26.5rem",
          height: "1.625rem",
          background: "#D8D2CB",
          display: "flex",
          alignItems: "center",
          position: "relative",
          paddingRight: "0.75rem",
          paddingTop: "1.5rem",
          paddingBottom: "0.75rem",
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Typography
            variant="h6"
            sx={{
              width: "100%",
              opacity: 1,
            }}
          >
            {t("CLIENT_OFFBOARDING:CLOSE_ACCOUNT")}
          </Typography>
        </Box>
        <IconButton onClick={onClose} size="small" sx={{ position: "absolute", right: "1rem" }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent
        sx={{
          width: "25.75rem",
          display: "flex",
          flexDirection: "column",
          padding: "0 1.5rem 1.5rem",
        }}
      >
        <Typography
          sx={{
            margin: "1rem 0 1.5rem",
          }}
        >
          {t("CLIENT_OFFBOARDING:CLOSE_CONFIRMATION_CONTENT")}
        </Typography>
        <Box
          sx={{
            borderTop: "1px dashed #CCCCCC",
            width: "auto",
            padding: "0 1.5rem",
          }}
        />
        <DialogActions
          sx={{
            justifyContent: "flex-end",
            padding: "1.5rem 0 0",
          }}
        >
          <Box
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <StyledButton variant="outlined" onClick={onClose}>
              {t("CLIENT_OFFBOARDING:DO_NOT_PROCEED_BUTTON")}
            </StyledButton>
            <StyledButton variant="contained" onClick={onConfirm}>
              {t("CLIENT_OFFBOARDING:CONFIRM_CLOSE_BUTTON")}
            </StyledButton>
          </Box>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

export default CloseAccountConfirmation;
