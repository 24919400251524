import React, { useContext, useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import { useLocation, useNavigate } from "react-router-dom";

import TableRowWithDetails from "./TableRow";
import Snackbar from "../commons/Snackbar";
import NoRecords from "../commons/NoRecords";
import { TabContext } from "../Tabs/CustomTabs";
import TableSkeleton from "../commons/Table/SkeletonTableLoader";

function TenantsTable({ headerColumns, fetchData, noRecordsFound, debounced, setDebounced, tabNameKey }) {
  const { state } = useLocation();
  const { activeIndex } = useContext(TabContext);
  const [expandedRow, setExpandedRow] = useState(state?.businessEmailAddress ?? null);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(7);

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [updateFlag, setUpdateFlag] = useState(false); // To fetch data again when a request is approved/rejected

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleRowClick = (rowId) => {
    setExpandedRow((prevExpandedRow) => (prevExpandedRow === rowId ? null : rowId));
  };

  const [snackbarOpen, setSnackbarOpen] = useState(state?.snackbarOpen ?? false);
  const [snackbarMsg, setSnackbarMsg] = useState(state?.snackbarMsg ?? "");
  const [snackbarVariant, setSnackbarVariant] = useState(state?.snackbarVariant ?? "");

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
    setSnackbarMsg("");
    setSnackbarVariant("");
  };

  const handleDataUpdate = (actionSnackbarMsg, actionSnackbarVariant, err) => {
    setSnackbarOpen(true);
    setSnackbarMsg(actionSnackbarMsg);
    setSnackbarVariant(actionSnackbarVariant);
    if (!err) setUpdateFlag((prevUpdateFlag) => !prevUpdateFlag);
  };

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);

    const fetchDataAndSetData = async () => {
      try {
        const response = await fetchData(page, rowsPerPage);
        setData(response);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDataAndSetData();
    setUpdateFlag(false);
    setDebounced(false);

    navigate(location.pathname, {});
  }, [page, rowsPerPage, updateFlag, activeIndex, debounced]);

  return (
    <>
      <TableContainer component={Paper} data-testid={`${tabNameKey}-table-container`}>
        <Table>
          <TableHead sx={{ backgroundColor: "#F8F8F7" }}>
            <TableRow data-testid={`${tabNameKey}-tableColumns-container`}>
              {headerColumns.map((column) => {
                return (
                  <TableCell
                    key={column.id}
                    data-testid={`table-column-${column.id}`}
                    sx={{
                      width: column.width,
                      pl: "32px",
                      fontWeight: 700,
                      fontSize: "14px",
                    }}
                  >
                    {column.label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody data-testid={`${tabNameKey}-tableBody-container`}>
            {isLoading ? (
              <TableSkeleton rowsNum={rowsPerPage} headerColumns={headerColumns} />
            ) : (
              (() => {
                switch (true) {
                  case noRecordsFound:
                    return (
                      <TableRow>
                        <TableCell colSpan={headerColumns.length} align="center">
                          <NoRecords />
                        </TableCell>
                      </TableRow>
                    );
                  default:
                    return data.tenants.map((row, index) => (
                      <TableRowWithDetails
                        key={row.id}
                        uniqueKey={`${row.id}`}
                        row={row}
                        expanded={expandedRow === row?.tenantIdentifier}
                        onRowClick={handleRowClick}
                        isEven={index % 2}
                        onApproveOrReject={handleDataUpdate}
                      />
                    ));
                }
              })()
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        rowsPerPageOptions={[5, 7, 10, 25]}
        count={data.totalRecords}
        page={page - 1}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        data-testid={`${tabNameKey}-tablePagination-container`}
      />
      <Snackbar
        open={snackbarOpen}
        handleSnackBarClose={handleSnackBarClose}
        msg={snackbarMsg}
        vertical="top"
        horizontal="right"
        variant={snackbarVariant}
      />
    </>
  );
}

export default TenantsTable;
