const AlertIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM13.0909 10.6161V5.81133H10.9237V10.6161C10.9237 11.1257 10.9489 11.6297 10.9993 12.1281C11.0553 12.6265 11.1309 13.1445 11.2261 13.6821H12.7885C12.8837 13.1445 12.9565 12.6265 13.0069 12.1281C13.0629 11.6297 13.0909 11.1257 13.0909 10.6161ZM10.7137 16.2609C10.6465 16.4233 10.6129 16.5969 10.6129 16.7817C10.6129 16.9721 10.6465 17.1485 10.7137 17.3109C10.7865 17.4733 10.8845 17.6161 11.0077 17.7393C11.1309 17.8569 11.2737 17.9493 11.4361 18.0165C11.6041 18.0837 11.7861 18.1173 11.9821 18.1173C12.1725 18.1173 12.3489 18.0837 12.5113 18.0165C12.6793 17.9493 12.8249 17.8569 12.9481 17.7393C13.0713 17.6161 13.1693 17.4733 13.2421 17.3109C13.3149 17.1485 13.3513 16.9721 13.3513 16.7817C13.3513 16.5969 13.3149 16.4233 13.2421 16.2609C13.1693 16.0929 13.0713 15.9501 12.9481 15.8325C12.8249 15.7093 12.6793 15.6141 12.5113 15.5469C12.3489 15.4741 12.1725 15.4377 11.9821 15.4377C11.7861 15.4377 11.6041 15.4741 11.4361 15.5469C11.2737 15.6141 11.1309 15.7093 11.0077 15.8325C10.8845 15.9501 10.7865 16.0929 10.7137 16.2609Z"
      fill="#E28001"
    />
  </svg>
);

export default AlertIcon;
