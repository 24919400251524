import React from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { getBackgroundColor, getLeftBorderColor, getHeadingColor, getIcon } from "./Utils";

const InformationPanel = ({ type, heading, body, showIcon = true }) => {
  const Icon = getIcon(type);
  const genericPadding = "8px 16px 12px 8px";
  const infoPadding = "8px 16px 8px 8px";
  const isBodyString = typeof body === "string";

  return (
    <Paper
      sx={{
        display: "flex",
        alignItems: "flex-start",
        width: "100%",
        minHeight: "1.813rem",
        padding: heading ? genericPadding : infoPadding,
        borderRadius: "4px",
        position: "relative",
        backgroundColor: getBackgroundColor(type),
        borderLeft: `4px solid ${getLeftBorderColor(type)}`,
      }}
      elevation={0}
    >
      <Box
        sx={{
          padding: "4px 0",
          display: "flex",
          flex: "1 0 0",
          gap: "8px",
          alignItems: "flex-start",
        }}
      >
        {showIcon ? <Icon /> : null}
        <Box
          display="flex"
          flexDirection="column"
          alignSelf="flex-start"
          flex="1 0 0"
          gap="0"
          paddingTop={showIcon ? "2px" : 0}
        >
          <Typography variant="body2" fontWeight={600} display="block" color={getHeadingColor(type)}>
            {heading}
          </Typography>
          {isBodyString ? <Typography variant="body2">{body}</Typography> : body}
        </Box>
      </Box>
    </Paper>
  );
};

InformationPanel.displayName = "Information Panel";

export default InformationPanel;
